<template>
  <footer v-editable="config" :data-theme="theme">
    <div class="container container--bleed">
      <div class="row row--full">
        <div class="footer-logo">
          <a href="/" title="Kudosity Logo" v-if="logo?.filename">
            <img :src="logo?.filename" alt="Kudosity Logo" />
          </a>
        </div>
      </div>
      <div class="row row--full footer-menu-row">
        <div class="footer-menu-group" v-for="(group, g) in config.footer_menu" :key="g" v-editable="group">
          <div class="footer-menu" v-for="(menu, m) in group.items" :key="m" v-editable="menu">
            <template v-if="menu?.label">
              <a v-if="menu?.link?.cached_url" :href="getLinkFieldUrl(menu?.link)" class="footer-menu-label" v-text="menu.label" :target="menu?.link?.target"></a>
              <div v-else class="footer-menu-label" v-text="menu.label"></div>
            </template>
            <ul class="footer-menu-list">
              <li v-for="(link, l) in menu.menu" :key="l" class="footer-link" v-editable="link">
                <a :href="getLinkFieldUrl(link.link)" v-text="link.label"></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row row-full">
        <div class="footer-sub">
          <div class="footer-social-links" v-if="config.social_links">
            <a :href="getLinkFieldUrl(link.url)" v-for="(link, l) in config.social_links" :key="l" class="footer-social-link" v-editable="link" title="`Kudosity ${link.icon} URL`">
              <img :src="`/icons/icon-${link.icon}-${theme}.svg`" :alt="`Social Icon ${link.icon}`" />
            </a>
          </div>
          <div class="footer-copyright" v-text="config.copyright"></div>
          <div class="footer-submenu" v-if="config.footer_submenu">
            <a :href="getLinkFieldUrl(link.link)" v-for="(link, l) in config.footer_submenu" :key="l" class="footer-submenu-link" v-text="link.label" v-editable="link"> </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { usePageStore } from "@/stores/page";
const props = defineProps({
  config: {
    type: Object,
  },
});

const state = usePageStore();
const theme = computed(() => state.footer_theme);

const logo = computed(() => {
  if (theme.value === "light") {
    return props.config.footer_logo_dark;
  }
  return props.config.footer_logo;
});
</script>
